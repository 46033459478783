import { Controller } from "stimulus";
import { debounce, getCSRFToken, setUpdatedCsrfMetaContent } from "../../util";
import { getActiveCurrency } from "../../util/currency";
import {
  EventLoadAddCoinsSection,
  EventOnboardingAddCoin,
  EventOnboardingDropdown,
  EventOnboardingRemoveCoin,
  EventTabItemPointerentered
} from "../../events";

export default class extends Controller {
  // keep track of coins from Get Recommendations sub-step
  _coinsFromRecommendations = {};

  static targets = [
    // Add coins page
    "searchInput", "portfolioDropdown", "portfolioDropdownValue",

    "highlightPills", "searchContainer",

    "addCoinsSkeleton", "addCoinsSection",

    "defaultCoinsContainer", "searchResultsContainer", "loading",
  ];

  // We use this variable to prevent race conditions from AJAX calls
  // completing at different times.
  _ajaxCount = 0;
  _selectedPill = null;

  async connect() {
    await this._fetchPortfolioCoinsInfo();

    this._populatePortfolioDropdown();
    this._updateAllStars();

    this._addCoinsSectionListener();
    this.handleSearchInput = debounce(this.handleSearchInput.bind(this), 300);
    this._selectedPill = this.highlightPillsTarget.querySelector("nav a.selected");
  }

  handlePortfolioSelect(e) {
    this._portfolioId = e.currentTarget.dataset.value;
    this._portfolioCoinIds = JSON.parse(e.currentTarget.dataset.portfolioCoinIds);

    this._syncPortfolioDropdownValue();
    this._dispatchPortfolioValue();
    this._updateAllStars();
  }

  handleSearchInput() {
    const query = this.searchInputTarget.value;

    this._ajaxCount += 1;
    const ajaxCount = this._ajaxCount;

    if (!query) {
      this.highlightPillsTarget.classList.remove("tw-hidden");

      this.defaultCoinsContainerTarget.classList.remove("tw-hidden");
      this.searchResultsContainerTarget.classList.add("tw-hidden");

      this._updateAllStars();
      this.loadingTarget.classList.add("!tw-hidden");

      return;
    }

    this.loadingTarget.classList.remove("!tw-hidden");

    const params = new URLSearchParams();
    params.set("query", query);
    params.set("vs_currency", getActiveCurrency());

    const url = `/${I18n.locale}/onboarding/search?${params}`;
    fetch(url, { credentials: "same-origin" })
      .then(response => response.text())
      .then(result => {
        if (!result || this._ajaxCount !== ajaxCount) {
          return;
        }

        this.highlightPillsTarget.classList.add("tw-hidden");

        this.defaultCoinsContainerTarget.classList.add("tw-hidden");
        this.searchResultsContainerTarget.innerHTML = result;
        this.searchResultsContainerTarget.classList.remove("tw-hidden");

        this._updateAllStars();
        this.loadingTarget.classList.add("!tw-hidden");
      });
  }

  handleCoin(e) {
    const coinId = +e.currentTarget.dataset.coinId;
    const isRecommendations = !!e.currentTarget.dataset.recommendations;

    if (this._portfolioCoinIds.includes(coinId)) {
      this._removeCoinFromPortfolio(coinId, isRecommendations);
      return;
    }

    this._addCoinToPortfolio(coinId, isRecommendations);
  }

  updateSelectedPill(e) {
    if (this._selectedPill === e.currentTarget) {
      return;
    }

    this._selectedPill = e.currentTarget;
  }

  updateActivePill(e) {
    const pillIdOfMouseoveredElement = e.currentTarget.dataset.tabId;

    if (pillIdOfMouseoveredElement === this._selectedPill.id) {
      return;
    }

    this._selectedPill = this.highlightPillsTarget.querySelector(`#${pillIdOfMouseoveredElement}`);
    this._selectedPill.dispatchEvent(new CustomEvent(EventTabItemPointerentered));
    this._selectedPill.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
  }

  _removeCoinFromPortfolio(coinId, isRecommendations) {
    Modal.danger(
      I18n.t("portfolio.remove_coin.title"),
      I18n.t("portfolio.remove_coin.body"),
      I18n.t("labels.confirm"),
      I18n.t("labels.cancel")
    ).then(async (result) => {
      if (!result.confirm) {
        return;
      }

      await setUpdatedCsrfMetaContent();
      const url = `/en/portfolios/${this._portfolioId}/coins/${coinId}`;
      fetch(url, {
        credentials: "same-origin",
        method: "DELETE",
        headers: {
          "X-CSRF-Token": getCSRFToken()
        }
      }).then((response) => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"));
        } else {
          this._removeFromPortfolioCoins(coinId, this._portfolioCoinIds);
          this._updateAllStars();
          this._updateItemPortfolioCoinsIds();

          if (this._coinsFromRecommendations[this._portfolioId]?.includes(coinId)) {
            this._removeFromPortfolioCoins(coinId, this._coinsFromRecommendations[this._portfolioId]);
            this._dispatchRemoveCoin();
          }
        }
      }).catch(e => Toaster.toast(`An unexpected error occurred. ${e}`, {
        icon: {
          classes: "fas fa-times tw-text-danger-500"
        }
      }));
    });
  }

  async _addCoinToPortfolio(coinId, isRecommendations) {
    let loadingStep = isRecommendations ? 1 : 0;
    this.loadingTargets[loadingStep].classList.remove("!tw-hidden");

    await setUpdatedCsrfMetaContent();
    const url = `/en/portfolios/${this._portfolioId}/coins/${coinId}`;
    fetch(url, {
      credentials: "same-origin",
      method: "POST",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        "X-CSRF-Token": getCSRFToken(),
      }
    }).then((response) => {
      if (!response.ok) {
        Toaster.toast("An unknown error occurred", {
          icon: {
            classes: "fas fa-times tw-text-danger-500"
          }
        });
      } else {
        this._portfolioCoinIds.push(coinId);
        this._updateAllStars();
        this._updateItemPortfolioCoinsIds();
        this.loadingTargets[loadingStep].classList.add("!tw-hidden");

        if (isRecommendations) {
          this._addCoinIdIntoRecommendationCoinsHash(coinId);
          this._dispatchAddCoin();
        }

        this._trackEvent("add_portfolio_coin", {
          "event_url": window.location.pathname,
          "event_label": "onboarding",
          "onboarding_type": this._getUserOnboardingType(),
        });
      }
    })
  }

  _addCoinsSectionListener() {
    window.addEventListener(EventLoadAddCoinsSection, () => {
      this._fetchAddCoinsSection();
    });
  }

  _fetchAddCoinsSection() {
    fetch(`/${I18n.locale}/onboarding/add_coins_section`)
      .then(async response => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"), {
            icon: {
              classes: "fas fa-times tw-text-danger-500"
            }
          });

          throw new Error(`Could not retrieve coins. Error ${response.status}.`);
        }

        this.addCoinsSectionTarget.innerHTML = await response.text();
        this._updateAllStars();

        this.addCoinsSkeletonTarget.classList.add("tw-hidden");
        this.addCoinsSectionTarget.classList.remove("tw-hidden");
      })
      .catch(e => Toaster.toast(`An unexpected error occurred.`, {
        icon: {
          classes: "fas fa-times tw-text-danger-500"
        }
      }));
  }

  _populatePortfolioDropdown() {
    fetch(`/${I18n.locale}/onboarding/portfolio_dropdown`)
      .then(async response => {
        if (!response.ok) {
          return Promise.reject("Could not fetch portfolio dropdown.");
        }

        const dropdown_result = await response.text();
        this.portfolioDropdownTargets.forEach((dropdown)=>{
          dropdown.innerHTML = dropdown_result;
        });
      });
  }

  _syncPortfolioDropdownValue() {
    this.portfolioDropdownValueTargets.forEach((dropdown)=>{
      dropdown.value = this._portfolioId;
    });
  }

  async _fetchPortfolioCoinsInfo() {
    await fetch(`/onboarding/portfolio_coins_info`)
      .then(async response => {
        if (!response.ok) {
          return Promise.reject("Could not fetch user info.");
        }

        const portfolioCoinsInfo = await response.json();
        this._portfolioId = portfolioCoinsInfo.primaryPortfolioId;
        this._portfolioCoinIds = JSON.parse(portfolioCoinsInfo.primaryCoinIds);
        this._newUser = portfolioCoinsInfo.newUser;

        this._dispatchPortfolioValue();
      });
  }

  _addCoinIdIntoRecommendationCoinsHash(coinId) {
    if (!this._coinsFromRecommendations[this._portfolioId]) {
      this._coinsFromRecommendations[this._portfolioId] = [];
    }

    this._coinsFromRecommendations[this._portfolioId].push(coinId);
  }

  _dispatchPortfolioValue() {
    window.dispatchEvent(
      new CustomEvent(EventOnboardingDropdown, {
        detail: {
          portfolioId: this._portfolioId,
          portfolioCoinIds: this._portfolioCoinIds,
        }
      })
    );
  }

  _dispatchAddCoin() {
    window.dispatchEvent(
      new CustomEvent(EventOnboardingAddCoin)
    );
  }

  _dispatchRemoveCoin() {
    window.dispatchEvent(
      new CustomEvent(EventOnboardingRemoveCoin)
    );
  }

  _updateItemPortfolioCoinsIds() {
    let nodes = document.querySelectorAll(`[data-portfolio-dropdown-id="item-portfolio-${this._portfolioId}"]`);
    let portfolioCoinIds = JSON.stringify(this._portfolioCoinIds)

    nodes.forEach((node) => {
      node.setAttribute("data-portfolio-coin-ids", portfolioCoinIds);
    });
  }

  _updateAllStars() {
    const coinIdStarsMap = {}
    for (const id of this._portfolioCoinIds) {
      coinIdStarsMap[id] = true;
    }

    this.element.querySelectorAll(`i.fa-star`).forEach(star => {
      const coinId = +star.getAttribute("data-coin-id");
      if (!coinId) return;

      const starred = !!coinIdStarsMap[coinId];

      star.classList.toggle("far", !starred);
      star.classList.toggle("fas", starred);
      star.classList.toggle("tw-text-yellow-500", starred);
    });
  }

  _removeFromPortfolioCoins(coinId, array) {
    let coinIdIndex = array.indexOf(coinId);

    if (coinIdIndex !== -1) {
      array.splice(coinIdIndex, 1);
    }
  }

  _getUserOnboardingType() {
    return this._newUser ? "new" : "continuous";
  }

  _trackEvent(eventName, eventParams = {}) {
    if (typeof gtag === 'undefined') return;

    gtag("event", eventName, eventParams);
  }
}
