import { Controller } from "stimulus";
import {
  EventLoadCategoriesChips,
  EventLoadChainsChips,
  EventMoreContentLoaded,
  EventOnboardingAddCoin,
  EventOnboardingDropdown,
  EventOnboardingRemoveCoin
} from "../../events";

const CHIP_MAX_LIMIT = 5;

export default class extends Controller {
  // For handling adding coins into portfolio
  _portfolioId = 0;
  _portfolioCoinIds = [];
  _addedCoinsCount = 0;

  idCounters = {
    chain: 0,
    category: 0,
  }

  selectedIds = {
    chain: [],
    category: [],
  };

  static targets = [
    // Step 1
    "chainContainer", "categoriesContainer", "chainCounter", "categoriesCounter", "nextButton",
    "chainsSkeleton", "chainsChips", "categoriesSkeleton", "categoriesChips",

    // Step 2
    "loading", "recommendationsContainer", "coinsCounter", "recommendedCoinsActions", "emptyCoinsActions"
  ];

  connect() {
    this._handleMoreChipLoaded();

    this._setupAddCoinListener();
    this._setupRemoveCoinListener();
    this._setupPortfolioValueListener();
    this._setupChainsChipsListener();
    this._setupCategoriesChipsListener();
  }

  handleSelect(e) {
    const selectedButton = e.currentTarget;
    const type = selectedButton.dataset.type;
    const id = type === "chain" ? +selectedButton.dataset.chainId : +selectedButton.dataset.categoryId;

    if (this._hasCurrentId(type, id)) {
      this._removeCurrentId(type, id);
    } else {
      this._addCurrentId(type, id);
    }

    this._toggleNextButton();
    this._toggleChipsFromLimit(type);
  }

  getRecommendations() {
    this.loadingTarget.classList.toggle("!tw-hidden");

    const params = new URLSearchParams();
    params.set("user_onboarding", "true");

    if ( this.selectedIds["chain"].toString() !== "") {
      params.set("chains", this.selectedIds["chain"].toString());
    }

    if ( this.selectedIds["category"].toString() !== "") {
      params.set("categories", this.selectedIds["category"].toString());
    }

    const url = `/${I18n.locale}/portfolios/${this._portfolioId}/recommendations?${params}`;
    fetch(url, { credentials: "same-origin" })
      .then(response => response.json())
      .then(result => {
        this.recommendationsContainerTarget.innerHTML = result.html;
        this.recommendationsContainerTarget.classList.remove("tw-hidden");
        this._updateAllStars();
        this.loadingTarget.classList.toggle("!tw-hidden");

        if (result.no_coins) {
          this.emptyCoinsActionsTarget.classList.remove("!tw-hidden");
          this.recommendedCoinsActionsTarget.classList.add("!tw-hidden");
        } else {
          this.recommendedCoinsActionsTarget.classList.remove("!tw-hidden");
          this.emptyCoinsActionsTarget.classList.add("!tw-hidden");
        }
      });
  }

  _setupAddCoinListener() {
    window.addEventListener(EventOnboardingAddCoin, (e) => {
      this._addedCoinsCount++;
      this.coinsCounterTarget.innerHTML = this._addedCoinsCount;
    });
  }

  _setupRemoveCoinListener() {
    window.addEventListener(EventOnboardingRemoveCoin, (e) => {
      if (this._addedCoinsCount > 0) {
        this._addedCoinsCount--;
        this.coinsCounterTarget.innerHTML = this._addedCoinsCount;
      }
    });
  }

  _setupPortfolioValueListener() {
    window.addEventListener(EventOnboardingDropdown, (e) => {
      this._portfolioId = e.detail.portfolioId;
      this._portfolioCoinIds = e.detail.portfolioCoinIds;
    });
  }

  _setupChainsChipsListener() {
    window.addEventListener(EventLoadChainsChips, () => {
      this._fetchChainsChips();
    });
  }

  _setupCategoriesChipsListener() {
    window.addEventListener(EventLoadCategoriesChips, () => {
      this._fetchCategoriesChips()
    });
  }

  _fetchChainsChips() {
    fetch(`/${I18n.locale}/onboarding/chains_chips`)
      .then(async response => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"), {
            icon: {
              classes: "fas fa-times tw-text-danger-500"
            }
          });

          throw new Error(`Could not retrieve chains. Error ${response.status}.`);
        }

        this.chainsChipsTarget.innerHTML = await response.text();
        this.chainsSkeletonTarget.classList.add("tw-hidden");
        this.chainsChipsTarget.classList.remove("tw-hidden");
      })
      .catch(e => Toaster.toast(`An unexpected error occurred.`, {
      icon: {
        classes: "fas fa-times tw-text-danger-500"
      }
    }));
  }

  _fetchCategoriesChips() {
    fetch(`/${I18n.locale}/onboarding/categories_chips`)
      .then(async response => {
        if (!response.ok) {
          Toaster.toast(I18n.t("metamask.unknown_error"), {
            icon: {
              classes: "fas fa-times tw-text-danger-500"
            }
          });

          throw new Error(`Could not retrieve categories. Error ${response.status}.`);
        }

        this.categoriesChipsTarget.innerHTML = await response.text();
        this.categoriesSkeletonTarget.classList.add("tw-hidden");
        this.categoriesChipsTarget.classList.remove("tw-hidden");
      })
      .catch(e => Toaster.toast(`An unexpected error occurred.`, {
        icon: {
          classes: "fas fa-times tw-text-danger-500"
        }
      }));
  }

  _updateAllStars() {
    const coinIdStarsMap = {}
    for (const id of this._portfolioCoinIds) {
      coinIdStarsMap[id] = true;
    }

    this.element.querySelectorAll(`i.fa-star`).forEach(star => {
      const coinId = +star.getAttribute("data-coin-id");
      if (!coinId) return;

      const starred = !!coinIdStarsMap[coinId];
      star.classList.toggle("far", !starred);
      star.classList.toggle("fas", starred);
      star.classList.toggle("tw-text-yellow-500", starred);
    });
  }

  _handleMoreChipLoaded() {
    window.addEventListener(EventMoreContentLoaded, (e) => {
      this._toggleChipsFromLimit("chain");
      this._toggleChipsFromLimit("category");

      this._removeDuplicateChips("chain");
      this._removeDuplicateChips("category");
    });
  }

  _removeDuplicateChips(type) {
    const container = type === "chain" ? this.chainContainerTarget : this.categoriesContainerTarget;
    const chips = container.querySelectorAll(`button[data-type="${type}"]`);
    const ids = new Set();

    chips.forEach(chip => {
      const chipId = chip.getAttribute(`data-${type}-id`);
      if (ids.has(chipId)) {
        chip.remove();
      } else {
        ids.add(chipId);
      }
    });
  }

  _hasCurrentId(type, id) {
    return this.selectedIds[type].includes(id);
  }

  _addCurrentId(type, id) {
    this.idCounters[type]++;
    this.selectedIds[type].push(id);

    const target = type === "chain" ? this.chainCounterTarget : this.categoriesCounterTarget;
    target.innerHTML = this.idCounters[type];
  }

  _removeCurrentId(type, id) {
    this.idCounters[type]--;

    let removeIdIndex = this.selectedIds[type].indexOf(id);
    this.selectedIds[type].splice(removeIdIndex, 1);

    const target = type === "chain" ? this.chainCounterTarget : this.categoriesCounterTarget;
    target.innerHTML = this.idCounters[type];
  }

  _toggleNextButton() {
    if (this._totalChipsSelected() > 0) {
      this._enableButton();
    } else {
      this._disableButton();
    }
  }

  _totalChipsSelected() {
    return Object.values(this.idCounters).reduce((total, count) => total + count, 0);
  }

  _enableButton() {
    this.nextButtonTarget.disabled = false;
  }

  _disableButton() {
    this.nextButtonTarget.disabled = true;
  }

  _toggleChipsFromLimit(type) {
    const container = type === "chain" ? this.chainContainerTarget : this.categoriesContainerTarget;

    if (this.idCounters[type] === CHIP_MAX_LIMIT) {
      this._toggleUnselectedChips(type, container, true);
    } else {
      this._toggleUnselectedChips(type, container, false);
    }
  }

  _toggleUnselectedChips(type, container, disable) {
    const chips = container.querySelectorAll(`button[data-type="${type}"]`);

    chips.forEach(chip => {
      const chipId = +chip.getAttribute(`data-${type}-id`);
      if (!chipId) return;

      if (!this.selectedIds[type].includes(chipId)) {
        chip.disabled = disable;
      }
    });
  }
}
